import { useEffect, useState } from 'react';
import '../css/ListBikes.css'
import RedBooking from './RedBooking';
import CountIcom from '../js/CountIcom'


const moment = require('moment');

const ListBooking = props => {
 

    const [allData, setAllData] = useState(null)

    const [isKok, setIsKok] = useState(props.isKok)

    //Отображение нужной аренды
    const [rent, setRent] = useState(null)

    const [allExpenses, setAllExpenses] = useState(null)

    useEffect(()=> { 
      if (props.allBooking) {
        // Извлечение бронирований с сортировкой по дате окончания аренды
        const bikesData = props.allBooking;
        const filtrBook = bikesData.filter(item => item.addBooking.id === props.id);
      
        const sortedUpSort = [...filtrBook].sort((a, b) => {
          const dateA = a.addBooking.dateEnd ? new Date(a.addBooking.dateEnd).getTime() : new Date('9999-12-31').getTime();
          const dateB = b.addBooking.dateEnd ? new Date(b.addBooking.dateEnd).getTime() : new Date('9999-12-31').getTime();
          return dateB - dateA;
        });
        
        setAllData(sortedUpSort);
      }
      
      if (props.allExpenses) {
        const expData = props.allExpenses
        setAllExpenses(expData.filter(item => item.addExp.id === props.id));
      }
      // eslint-disable-next-line
  },[])

    const showBooking = (item) => {
      setRent(item)
        // navigate(`/showbike${id}`)
                // console.log(item)
    }

    return (
      <div>
        {!isKok && <CountIcom allData={allData} bike={props.item} allExpenses={allExpenses}/>}
        <h2>All rent</h2>
      <table className='table'>
        <thead >
          <tr>
              <th>Name customer</th> 
              <th>Date/time start rent</th> 
              <th>Date/time finish rent</th> 
              <th>Left days before finish rent</th>
              <th>Price</th>
              <th>Deposit</th>
              {/* <th>Комментарий</th> */}
              
          </tr>
        </thead>
        <tbody>
          {allData && allData.map((item, ind)=> 
            <tr 
            style = {
              Math.ceil((new Date(item.addBooking.dateEnd) - new Date()) / (1000 * 60 * 60 * 24))<0 ?
              {cursor: "pointer", backgroundColor: "#b2edb8"}
              : { cursor: "pointer", backgroundColor: "#edd3b2" }
            }
            // style={
            //   props.item.available && ind !== allData.length - 1
            //     ? { cursor: "pointer", backgroundColor: "#b2edb8" }
            //     : { cursor: "pointer", backgroundColor: "#edd3b2" }
            // }
             onClick={()=> showBooking(item)} key={ind}>
                <td>{item.addBooking.fio}</td>
                <td>{(moment(item.addBooking.dateStart).format("DD-MM-YYYY HH:mm"))}</td>
                <td>{(moment(item.addBooking.dateEnd).format("DD-MM-YYYY HH:mm"))}</td>
                <td>{Math.ceil((new Date(item.addBooking.dateEnd) - new Date()) / (1000 * 60 * 60 * 24))<0 ? '-' : Math.ceil((new Date(item.addBooking.dateEnd) - new Date()) / (1000 * 60 * 60 * 24))}</td>
                <td>{item.addBooking.totalPrice}</td>
                <td>{item.addBooking.deposit}</td>
                {/* <td>{item.addBooking.comment}</td> */}
            </tr>
            
          )}
        </tbody>
      </table>
      {rent&& 
      <RedBooking isKok={isKok} isKolcov={props.isKolcov} rent={rent} item={props.item} user={props.user}/>}
      </div>
    );
  }

export default ListBooking