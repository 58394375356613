import React, { useEffect, useState } from "react";
import '../css/AddBike.css'
// import emailjs from '@emailjs/browser';
import facs1 from './facs'
import facs from './facsKol'
import stamp1 from "./Stamp";
import stamp from "./StampKol";

import { saveAs } from "file-saver";
import { Document, 
  Paragraph, 
  Packer, 
  TextRun, 
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  convertInchesToTwip,
  PageBreak,
  ImageRun,
  
} from "docx";

const moment = require('moment');
const Agreement = props => {

const [dateStart, setDateStart] = useState('01.09.2023')
const [dateEnd, setDateEnd] = useState('01.09.2023')

const [justStart, setJustStart] = useState('01.09.2023')
const [isKok, setIsKok] = useState(props.isKok)


useEffect(() => {
  const start = props.rent.addBooking.dateStart
  setDateStart(moment(start).format("DD-MM-YYYY HH:mm"))
  setJustStart(moment(start).format("DD-MM-YYYY"))
  const end = props.rent.addBooking.dateEnd
  setDateEnd(moment(end).format("DD-MM-YYYY HH:mm"))
  if (props) {
  }

}, [props]);

if (props.rent) {
const dateString = justStart.replace(/[-.]/g, '')
var numberDoc = props.rent.id.slice(-3).toUpperCase()+dateString
var numberReceipt = props.rent.id.slice(0, 3).toUpperCase()+dateString
} else {
  numberDoc = 1
}
const infoAgreem = props.rent


  const handleSaveClick = () => {

    const doc = new Document({
      
            sections: [
              {
                properties: {},

                children: [
                  new Paragraph({
                    children: [
                      new TextRun({size:'32',font: "optima", color:"#666666", text: "Lease Agreement (สัญญาเช่า)"})],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      before:0
                    },
                  }),
                  new Paragraph({
                    text: '',
                  }),
                  new Paragraph({
                    text: '',
                  }),
                  new Table({
                    width:{
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    indent: {
                      size: 1,
                      type: WidthType.DXA
                    },
                    rows: [
                    new TableRow({
                      children: [

                      new TableCell({
                        borders: {
                          top: {color: '000000'},
                          bottom: {color: '000000'},
                          left: {color: 'ffffff'},
                        },
                        margins: {
                          right: convertInchesToTwip(0.1)
                        },
                        width:{
                          size: 48,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children:[
                            new TextRun({size:'24',font: "optima", color:"#333333", bold: true, text: "Customer"}),
                          ],
      
                            spacing: {
                              line:350
                            },
                          }),
                        new Paragraph({
                            children:[
                            new TextRun({size:'20',font: "optima", color:"#333333", text: "Name: "}),
                            new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.fio}  `})],
      
                            spacing: {
                              line:350
                            },
                          }),
                          new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333",text: `Adress: `}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.place}  `})
                            ],
                              spacing: {
                                line:350
                              },
                            }),
                          new Paragraph({
                            children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: "Passport:  "}),
                            new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.passport}  `})],
                            spacing: {
                              line:350
                            }
                          }),
                          new Paragraph({
                              children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Phone:  "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.phone}  `})
                            ],
                              spacing: {
                                line:350
                              }
                            }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'20',font: "optima", color:"#333333", text: `Bike:`}),
                            new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${props.item.brand} ${props.item.model}  `}),
                          ],
                            spacing: {
                              line:350
                            },
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `Color - ${props.item.color} / № - ${props.item.regNumber}  `})
                          ],
                            spacing: {
                              line:350
                            },
                          }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: `Rate: `}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.priceDay}`}),
                              new TextRun({size:'20',font: "optima", color:"#333333", text: ` Baht/Day|Days Rental:  `}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.days}`}),
                            ],
      
                              spacing: {
                                line:350
                              }
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: "Total:  "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.totalPrice}`}),
                              new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
      
                              spacing: {
                                line:350
                              }
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: "Deposit:  "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.deposit}`}),
                              new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
      
                              spacing: {
                                line:350
                              }
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: "Delivery:  "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.delivery}`}),
                              new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
      
                              spacing: {
                                line:350
                              }
                            }),
                            new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Date Start to Rent/Time:  "})
                              ],
                                // alignment: AlignmentType.END,
                                spacing: {
                                  line:250
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${dateStart}`})],
                                // alignment: AlignmentType.END,
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Due Date Turn Back/Time:  "}),
                              ],
                                // alignment: AlignmentType.END,
                                spacing: {
                                  line:250
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${dateEnd}`})],
                                // alignment: AlignmentType.END,
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: `Helmets:  `}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.helmets}`})],
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'24', font: "optima", color:"#333333", bold: true, text: "Exceeding Time Charge Tse. 50.- Baht per Hour"}),
                              ],
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                  line:250
                                }
                              }),
                              new Paragraph({
                                text: '',
                              }),
                              new Paragraph({
                                text: '',
                              }),
                              new Paragraph({
                                text: '',
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'24', font: "optima", color:"#333333", text: "Empty "}),
                                new TextRun({size:'24', font: "optima", color:"#333333", underline: {}, text: "|    |    |    |    |    |    |    | "}),
                                new TextRun({size:'24', font: "optima", color:"#333333", text: " Full"}),
                              ],
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                  line:250
                                }
                              }),
                              new Paragraph({
                                text: '',
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'24', font: "optima", color:"#333333", text: "Gasoline Level (95)"}),
                              ],
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                  line:250
                                }
                              }),
                              
                    ]
                    }),

                    new TableCell({
                      borders: {
                        top: {color: 'ffffff'},
                        bottom: {color: 'ffffff'},
                        right: {color: '000000'},
                        left: {color: '000000'},
                      },
                      margins: {
                        left: convertInchesToTwip(0.1)
                      },
                      width:{
                        size: 52,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children:[
                          new TextRun({size:'16', font: "optima", color:"#333333", underline: {}, bold: true, text: "Please read the agreement by early ally for understand"}),
                        ],
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", bold: true, text: "By this lease agreement, “Lessee” agreement with conditions as following:"}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "“Lessee” rent a personal car or motorbike and paid the rent on the contract day. If there are any damages with breaking this contract by lessee, “Lessee” will pay to less or for all damages happened"}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” must be responsible for cost of gasoline, parking fees, toll fees, express way fees and penalty under law in case of the “Lessee” violates the traffic rules."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” must maintain the car in good condition and keep it at a safe place."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” coverts to use and maintain the rented car as follows:"}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For racing or test of any types."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For subletting to other person."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For any activity of which the purpose in illegal."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To make any modification, addition, removal of any accessory or part of the car or the bike."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To take the car or bike outside Phuket Island without prior agreement with the Lessor."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To take the car or bike outside the Kingdom of THAILAND."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "If the car or bike is taken outside Phuket Island, the Lessor will not be able to provide assistance in the event of an accident or breakdown, and the Lessee will be responsible for resolving all issues independently and at their own expense. Each car or bike is equipped with a GPS tracker to monitor its location."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "If loss or ruin happens by the others the “Lessee” must have full responsible."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "If any accident with the rental car or bike, “Lessee” must inform lesser immediately, any time 24 hours."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "Any damages with accident to the rental car or bike, “Lessee” must pay the basic price at first to lesser for 5,000 Thai Baht (Five Thousand Thai Baht) at least as an excess."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "In case of an accident made damages with cost more than the basic price that lessee paid to lesser, “Lessee” must pay in addition to be up to the actual cost of accident, no more than 50,000 Baht."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: "“Lessee” will return the rental car or bike immediately on due date as specify on this contract.   If lessee would like to contract lengthen, lessee has to contract lesser and if no return, lesser will be pursue for the rental car or bike and “Lessee” has to take responsibilities for all expense of the pursuance."}),
                        ],
                          spacing: {
                            line:250
                          }
                        }),
                        new Paragraph({
                          text: '',
                        }),

                        new Paragraph({
                          children:[
                          new TextRun({size:'15', font: "optima", color:"#333333", text: `* The “Leaser” assumes responsibility for all cost incurred for all damage or loss. *
                          `}),
                        ],
                          spacing: {
                            line:300
                          },
                        }),
                        new Paragraph({
                          text: '',
                        }),
]
                  })

                    ]}),
                      ]
                    }),
                    new Paragraph({
                      text: '',
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({size:'18', font: "optima", text: "Company: Rokki co., ltd", bold: true})],
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        line:200
                      },
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({size:'18', font: "optima", text: "WhatsApp: +66963362869(Russian, English)", bold: true})],
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        line:300
                      },
                    }),
                   new Paragraph({
                      children: [
                        new TextRun({size:'18', font: "optima", text: "Phone, Line: 064-410-4149 (Thai)", bold: true})],
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        line:300
                      },
                    }),

                    //ДОБАВЛЯЕМ Подпись В договор!!!!!!!
                    !isKok && 
                    new Paragraph({
                      children:[
                    
                    new ImageRun({
                      
                      data: Uint8Array.from(atob(facs), c =>
                        c.charCodeAt(0)
                      ),
                      transformation: {
                        width: 100,
                        height: 50
                      },

                      floating: {
                        zIndex: 10,
                        horizontalPosition: {
                            offset: 5014400,
                        },
                        verticalPosition: {
                            offset: 9094400,
                        },
                    },

                    }),
                  ],
                }),
                                    //ДОБАВЛЯЕМ Штамп В договор!!!!!!!
                                    new Paragraph({
                                      children:[
                                    new ImageRun({
                                      
                                      data: Uint8Array.from(atob(stamp), c =>
                                        c.charCodeAt(0)
                                      ),
                                      transformation: {
                                        width: 235,
                                        height: 110
                                      },
                
                                      floating: {
                                        zIndex: 10,
                                        horizontalPosition: {
                                            offset: 2754400,
                                        },
                                        verticalPosition: {
                                            offset: 8874400,
                                        },
                                    },
                
                                    }),
                                  ],
                                }),

                                new Paragraph({
                                  children:[
                                  new TextRun({size:'18', font: "optima", bold: true, color:"#333333", text: `                                             			                                  Mr. Phatsakon Kuadkaew`}),
                                ],
                                alignment: AlignmentType.CENTER,
                                  spacing: {
                                    line:200,
                                  },
                                }),
                                new Paragraph({
                                  text: '',
                                }),
                    
                    new Paragraph({
                      children:[
                      new TextRun({size:'18', font: "optima", color:"#333333", text: `………………………………….………          			                       …………………………………`}),
                    ],
                    alignment: AlignmentType.CENTER,
                      spacing: {
                        line:200,
                      },
                    }),
                    new Paragraph({
                      children:[
                      new TextRun({size:'18', font: "optima", color:"#333333", text: `The signature person rent                              		                                      Lessor signature`}),
                      new PageBreak()
                    ],
                    alignment: AlignmentType.CENTER,
                      spacing: {
                        line:250
                      },
                    }),
                    // Второй экземпляро договора!!!!!!!!!!!!!!!!
                    new Paragraph({
                      children: [
                        new TextRun({size:'32',font: "optima", color:"#666666", text: "Lease Agreement (สัญญาเช่า)"})],
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        before:0
                      },
                    }),
                    new Paragraph({
                      text: '',
                    }),
                    new Paragraph({
                      text: '',
                    }),
                    new Table({
                      width:{
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      indent: {
                        size: 1,
                        type: WidthType.DXA
                      },
                      rows: [
                      new TableRow({
                        children: [
  
                        new TableCell({
                          borders: {
                            top: {color: '000000'},
                            bottom: {color: '000000'},
                            left: {color: 'ffffff'},
                          },
                          margins: {
                            right: convertInchesToTwip(0.1)
                          },
                          width:{
                            size: 48,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              children:[
                              new TextRun({size:'24',font: "optima", color:"#333333", bold: true, text: "Customer"}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),
                          new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: "Name: "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.fio}  `})],
        
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333",text: `Adress: `}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.place}  `})
                              ],
                                spacing: {
                                  line:350
                                },
                              }),
                            new Paragraph({
                              children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Passport:  "}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.passport}  `})],
                              spacing: {
                                line:350
                              }
                            }),
                            new Paragraph({
                                children:[
                                  new TextRun({size:'20',font: "optima", color:"#333333", text: "Phone:  "}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.phone}  `})
                              ],
                                spacing: {
                                  line:350
                                }
                              }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',font: "optima", color:"#333333", text: `Bike:`}),
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${props.item.brand} ${props.item.model}  `}),
                            ],
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `Color - ${props.item.color} / № - ${props.item.regNumber}  `})
                            ],
                              spacing: {
                                line:350
                              },
                            }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: `Rate: `}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.priceDay}`}),
                                new TextRun({size:'20',font: "optima", color:"#333333", text: ` Baht/Day|Days Rental:  `}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.days}`}),
                              ],
        
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Total:  "}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.totalPrice}`}),
                                new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
        
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Deposit:  "}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.deposit}`}),
                                new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
        
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                children:[
                                new TextRun({size:'20',font: "optima", color:"#333333", text: "Delivery:  "}),
                                new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.delivery}`}),
                                new TextRun({size:'20',font: "optima", color:"#333333", text: " baht"})],
        
                                spacing: {
                                  line:350
                                }
                              }),
                              new Paragraph({
                                  children:[
                                  new TextRun({size:'20',font: "optima", color:"#333333", text: "Date Start to Rent/Time:  "})
                                ],
                                  // alignment: AlignmentType.END,
                                  spacing: {
                                    line:250
                                  }
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${dateStart}`})],
                                  // alignment: AlignmentType.END,
                                  spacing: {
                                    line:350
                                  }
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'20',font: "optima", color:"#333333", text: "Due Date Turn Back/Time:  "}),
                                ],
                                  // alignment: AlignmentType.END,
                                  spacing: {
                                    line:250
                                  }
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${dateEnd}`})],
                                  // alignment: AlignmentType.END,
                                  spacing: {
                                    line:350
                                  }
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'20',font: "optima", color:"#333333", text: `Helmets:  `}),
                                  new TextRun({size:'20',italics:true, font: "optima", color:"#333333", underline: {}, text: `  ${infoAgreem.addBooking.helmets}`})],
                                  spacing: {
                                    line:350
                                  }
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'24', font: "optima", color:"#333333", bold: true, text: "Exceeding Time Charge Tse. 50.- Baht per Hour"}),
                                ],
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    line:250
                                  }
                                }),
                                new Paragraph({
                                  text: '',
                                }),
                                new Paragraph({
                                  text: '',
                                }),
                                new Paragraph({
                                  text: '',
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'24', font: "optima", color:"#333333", text: "Empty "}),
                                  new TextRun({size:'24', font: "optima", color:"#333333", underline: {}, text: "|    |    |    |    |    |    |    | "}),
                                  new TextRun({size:'24', font: "optima", color:"#333333", text: " Full"}),
                                ],
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    line:250
                                  }
                                }),
                                new Paragraph({
                                  text: '',
                                }),
                                new Paragraph({
                                  children:[
                                  new TextRun({size:'24', font: "optima", color:"#333333", text: "Gasoline Level (95)"}),
                                ],
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    line:250
                                  }
                                }),
                                
                      ]
                      }),
  
                      new TableCell({
                        borders: {
                          top: {color: 'ffffff'},
                          bottom: {color: 'ffffff'},
                          right: {color: '000000'},
                          left: {color: '000000'},
                        },
                        margins: {
                          left: convertInchesToTwip(0.1)
                        },
                        width:{
                          size: 52,
                          type: WidthType.PERCENTAGE,
                        },
                        children: [
                          new Paragraph({
                            children:[
                            new TextRun({size:'16', font: "optima", color:"#333333", underline: {}, bold: true, text: "Please read the agreement by early ally for understand"}),
                          ],
                            alignment: AlignmentType.CENTER,
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", bold: true, text: "By this lease agreement, “Lessee” agreement with conditions as following:"}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "“Lessee” rent a personal car or motorbike and paid the rent on the contract day. If there are any damages with breaking this contract by lessee, “Lessee” will pay to less or for all damages happened"}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” must be responsible for cost of gasoline, parking fees, toll fees, express way fees and penalty under law in case of the “Lessee” violates the traffic rules."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” must maintain the car in good condition and keep it at a safe place."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "The “Lessee” coverts to use and maintain the rented car as follows:"}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For racing or test of any types."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For subletting to other person."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       For any activity of which the purpose in illegal."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To make any modification, addition, removal of any accessory or part of the car or the bike."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To take the car or bike outside Phuket Island without prior agreement with the Lessor."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "–       To take the car or bike outside the Kingdom of THAILAND."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "If the car or bike is taken outside Phuket Island, the Lessor will not be able to provide assistance in the event of an accident or breakdown, and the Lessee will be responsible for resolving all issues independently and at their own expense. Each car or bike is equipped with a GPS tracker to monitor its location."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "If loss or ruin happens by the others the “Lessee” must have full responsible."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "If any accident with the rental car or bike, “Lessee” must inform lesser immediately, any time 24 hours."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "Any damages with accident to the rental car or bike, “Lessee” must pay the basic price at first to lesser for 5,000 Thai Baht (Five Thousand Thai Baht) at least as an excess."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "In case of an accident made damages with cost more than the basic price that lessee paid to lesser, “Lessee” must pay in addition to be up to the actual cost of accident, no more than 50,000 Baht."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: "“Lessee” will return the rental car or bike immediately on due date as specify on this contract.   If lessee would like to contract lengthen, lessee has to contract lesser and if no return, lesser will be pursue for the rental car or bike and “Lessee” has to take responsibilities for all expense of the pursuance."}),
                          ],
                            spacing: {
                              line:250
                            }
                          }),
                          new Paragraph({
                            text: '',
                          }),
  
                          new Paragraph({
                            children:[
                            new TextRun({size:'15', font: "optima", color:"#333333", text: `* The “Leaser” assumes responsibility for all cost incurred for all damage or loss. *
                            `}),
                          ],
                            spacing: {
                              line:300
                            },
                          }),
                          new Paragraph({
                            text: '',
                          }),
  ]
                    })
  
                      ]}),
                        ]
                      }),
                      new Paragraph({
                        text: '',
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({size:'18', font: "optima", text: "Company: Rokki co., ltd", bold: true})],
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          line:200
                        },
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({size:'18', font: "optima", text: "WhatsApp: +66963362869(Russian, English)", bold: true})],
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          line:300
                        },
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({size:'18', font: "optima", text: "Phone, Line: 064-410-4149 (Thai)", bold: true})],
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          line:300
                        },
                      }),

                      //ДОБАВЛЯЕМ Подпись В договор!!!!!!!
                      !isKok && 
                      new Paragraph({
                        children:[
                      new ImageRun({
                        
                        data: Uint8Array.from(atob(facs), c =>
                          c.charCodeAt(0)
                        ),
                        transformation: {
                          width: 100,
                          height: 50
                        },
  
                        floating: {
                          zIndex: 10,
                          horizontalPosition: {
                              offset: 5014400,
                          },
                          verticalPosition: {
                              offset: 9094400,
                          },
                      },
  
                      }),
                    ],
                  }),
                                      //ДОБАВЛЯЕМ Штамп В договор!!!!!!!
                                      new Paragraph({
                                        children:[
                                      new ImageRun({
                                        
                                        data: Uint8Array.from(atob(stamp), c =>
                                          c.charCodeAt(0)
                                        ),
                                        transformation: {
                                          width: 235,
                                          height: 110
                                        },
                  
                                        floating: {
                                          zIndex: 10,
                                          horizontalPosition: {
                                              offset: 2754400,
                                          },
                                          verticalPosition: {
                                              offset: 8874400,
                                          },
                                      },
                  
                                      }),
                                    ],
                                  }),
  
                                  new Paragraph({
                                    children:[
                                    new TextRun({size:'18', font: "optima", bold: true, color:"#333333", text: `                                             			                                  Mr. Phatsakon Kuadkaew`}),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                    spacing: {
                                      line:200,
                                    },
                                  }),
                                  new Paragraph({
                                    text: '',
                                  }),
                      
                      new Paragraph({
                        children:[
                        new TextRun({size:'18', font: "optima", color:"#333333", text: `………………………………….………          			                       …………………………………`}),
                      ],
                      alignment: AlignmentType.CENTER,
                        spacing: {
                          line:200,
                        },
                      }),
                      new Paragraph({
                        children:[
                        new TextRun({size:'18', font: "optima", color:"#333333", text: `The signature person rent                              		                                      Lessor signature`}),
                        new PageBreak()
                      ],
                      alignment: AlignmentType.CENTER,
                        spacing: {
                          line:250
                        },
                      }),
                    //ДОкумент TAX INVOCE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    new Table({
                      width:{
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      indent: {
                        size: 1,
                        type: WidthType.DXA
                      },
                      rows: [
                      new TableRow({
                        children: [
  
                        new TableCell({
                          borders: {
                            top: {color: '000000'},
                            bottom: {color: '000000'},
                            left: {color: 'ffffff'},
                            right: {color: 'ffffff'},
                          },
                          margins: {
                            right: convertInchesToTwip(0.1)
                          },
                          width:{
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', text: "Rokki co., ltd (Head Office)"}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', text: "Tel. 0917957833"}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', color:"#808080", text: "Client"}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', text: `${infoAgreem.addBooking.fio}`}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', text: `${infoAgreem.addBooking.place}`}),
                            ],
        
                              spacing: {
                                line:350
                              },
                            }),

                          ]
                        }),
                        new TableCell({
                          borders: {
                            top: {color: '000000'},
                            bottom: {color: '000000'},
                            left: {color: 'ffffff'},
                            right: {color: 'ffffff'},
                          },
                          margins: {
                            right: convertInchesToTwip(0.1)
                          },
                          width:{
                            size:50,
                            type: WidthType.PERCENTAGE,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({size:'32', color:"#808080", text: "Tax Invoice"})],
                              alignment: AlignmentType.CENTER,
                              
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "Original (Set Document)"})],
                              alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "____________________________________"})],
                              alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Document №"}),
                                new TextRun({size:'20', text: `       ${numberDoc}`}),
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Due Date"}),
                                new TextRun({size:'20', text: `             ${justStart}`}),
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Date"}),
                                new TextRun({size:'20', text: `                     ${justStart}`}),
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Seller"}),
                                new TextRun({size:'20', text: `                   Mr. Phatsakon Kuadkaew`}),
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "____________________________________"})],
                              alignment: AlignmentType.CENTER,
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Contact"}),
                                new TextRun({size:'20', text: `                ${infoAgreem.addBooking.fio}`}),
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Phone"}),
                                new TextRun({size:'20', text: `                  ${infoAgreem.addBooking.phone}`}),
                              ]
                            }),
                          ]
                        })
                      ]
                    })
                  ]
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'24', color:"#808080", text: "___________________________________________________________________________"})],
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    line:250
                  },
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'20', text: "   #"}),
                    new TextRun({size:'20', text: "                             Description"}),
                    new TextRun({size:'20', text: `                              Quanity`}),
                    new TextRun({size:'20', text: `                              Unit Price`}),
                    new TextRun({size:'20', text: `                           Total`}),
                  ],
                  alignment: AlignmentType.JUSTIFIED,
                  spacing: {
                    line:350
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'20', color:"#808080", text: "   1"}),
                    new TextRun({size:'20', color:"#808080",text: `                        ${props.item.brand} ${props.item.model}`}),
                    new TextRun({size:'20', color:"#808080",text: `                       1 period`}),
                    new TextRun({size:'20', color:"#808080",text: `                                     ${infoAgreem.addBooking.totalPrice}.00 `}),
                    new TextRun({size:'20', color:"#808080",text: `                           ${infoAgreem.addBooking.totalPrice}.00`}),
                  ],
                  alignment: AlignmentType.JUSTIFIED,
                  spacing: {
                    line:350
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'24', color:"#808080", text: "___________________________________________________________________________"})],
                  alignment: AlignmentType.JUSTIFIED,
                  spacing: {
                    line:220
                  },
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'20', color:"#808080", text: "Total"}),
                    new TextRun({size:'20', text: `             ${infoAgreem.addBooking.totalPrice}.00 THB`}),
                  ],
                  alignment: AlignmentType.END,
                  spacing: {
                    line:400
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'20', color:"#808080", text: "Vat 0%"}),
                    new TextRun({size:'20', text: `                 0.00 THB`}),
                  ],
                  alignment: AlignmentType.END,
                  spacing: {
                    line:400
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({size:'20', color:"#808080", text: "Grand Total"}),
                    new TextRun({size:'20', text: `             ${infoAgreem.addBooking.totalPrice}.00 THB`}),
                  ],
                  alignment: AlignmentType.END,
                  spacing: {
                    line:400
                  },
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),


                                    //ДОБАВЛЯЕМ Подпись В Инвойс!!!!!!!
                                    !isKok && 
                                    new Paragraph({
                                      children:[
                                    new ImageRun({
                                      
                                      data: Uint8Array.from(atob(facs), c =>
                                        c.charCodeAt(0)
                                      ),
                                      transformation: {
                                        width: 100,
                                        height: 50
                                      },
                
                                      floating: {
                                        zIndex: 100,
                                        horizontalPosition: {
                                            offset: 4614400,
                                        },
                                        verticalPosition: {
                                            offset: 9114400,
                                        },
                                    },
                
                                    }),
                                  ],
                                }),
                                                                      //ДОБАВЛЯЕМ Штамп В инвойс!!!!!!!
                                                                      new Paragraph({
                                                                        children:[
                                                                      new ImageRun({
                                                                        
                                                                        data: Uint8Array.from(atob(stamp), c =>
                                                                          c.charCodeAt(0)
                                                                        ),
                                                                        transformation: {
                                                                          width: 235,
                                                                          height: 110
                                                                        },
                                                  
                                                                        floating: {
                                                                          zIndex: 10,
                                                                          horizontalPosition: {
                                                                              offset: 2454400,
                                                                          },
                                                                          verticalPosition: {
                                                                              offset: 8974400,
                                                                          },
                                                                      },
                                                  
                                                                      }),
                                                                    ],
                                                                  }),

                // new Paragraph({
                //   text: '',
                // }),
                // new Paragraph({
                //   text: '',
                // }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),
                new Paragraph({
                  text: '',
                }),


                new Table({
                  width:{
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  indent: {
                    size: 1,
                    type: WidthType.DXA
                  },
                  rows: [
                  new TableRow({
                    children: [

                    new TableCell({
                      borders: {
                        top: {color: '000000'},
                        bottom: {color: '000000'},
                        left: {color: 'ffffff'},
                        right: {color: 'ffffff'},
                      },
                      margins: {
                        right: convertInchesToTwip(0.1)
                      },
                      width:{
                        size: 48,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children:[
                          new TextRun({size:'20', text: `${infoAgreem.addBooking.fio}`}),
                        ],
    
                          spacing: {
                            line:350
                          },
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          text: '',
                        }),


                        
                        new Paragraph({
                          children:[
                          new TextRun({size:'20', color:"#808080", text: `________________  _______________`}),
                        ],
    
                          spacing: {
                            line:350
                          },
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'20', text: `      Recevied by                  Date`}),
                        ],
    
                          spacing: {
                            line:200
                          },
                        }),
                      ]
                    }),
                    
                    new TableCell({
                      borders: {
                        top: {color: '000000'},
                        bottom: {color: '000000'},
                        left: {color: 'ffffff'},
                        right: {color: 'ffffff'},
                      },
                      margins: {
                        right: convertInchesToTwip(0.1)
                      },
                      
                      width:{
                        size: 48,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          children:[
                          new TextRun({size:'20', text: "Rokki co., ltd"}),
                        ],
                          alignment: AlignmentType.END,
                          spacing: {
                            line:350
                          },
                        }),
                        new Paragraph({
                          text: '',
                        }),
                        new Paragraph({
                          text: '',
                        }),


                        new Paragraph({
                          children:[
                          new TextRun({size:'20', color:"#808080", text: `________________  _______________`}),
                        ],
                        alignment: AlignmentType.END,
                          spacing: {
                            line:350
                          },
                        }),
                        new Paragraph({
                          children:[
                          new TextRun({size:'20', text: `                              Approved by                  Date`}),
                          new PageBreak()
                        ],
                          spacing: {
                            line:200
                          },
                        }),

                      ]
                    })
                  ]
                })
              ]
            }),
                                //ДОкумент RECEIPT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                new Table({
                                  width:{
                                    size: 100,
                                    type: WidthType.PERCENTAGE,
                                  },
                                  indent: {
                                    size: 1,
                                    type: WidthType.DXA
                                  },
                                  rows: [
                                  new TableRow({
                                    children: [
              
                                    new TableCell({
                                      borders: {
                                        top: {color: '000000'},
                                        bottom: {color: '000000'},
                                        left: {color: 'ffffff'},
                                        right: {color: 'ffffff'},
                                      },
                                      margins: {
                                        right: convertInchesToTwip(0.1)
                                      },
                                      width:{
                                        size: 50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [
                                        new Paragraph({
                                          text: '',
                                        }),
                                        new Paragraph({
                                          children:[
                                          new TextRun({size:'20', text: "Rokki co., ltd (Head Office)"}),
                                        ],
                    
                                          spacing: {
                                            line:350
                                          },
                                        }),
                                        new Paragraph({
                                          children:[
                                          new TextRun({size:'20', text: "Tel. 0917957833"}),
                                        ],
                    
                                          spacing: {
                                            line:350
                                          },
                                        }),
                                        new Paragraph({
                                          text: '',
                                        }),
                                        new Paragraph({
                                          children:[
                                          new TextRun({size:'20', color:"#808080", text: "Client"}),
                                        ],
                    
                                          spacing: {
                                            line:350
                                          },
                                        }),
                                        new Paragraph({
                                          children:[
                                          new TextRun({size:'20', text: `${infoAgreem.addBooking.fio}`}),
                                        ],
                    
                                          spacing: {
                                            line:350
                                          },
                                        }),
                                        new Paragraph({
                                          children:[
                                          new TextRun({size:'20', text: `${infoAgreem.addBooking.place}`}),
                                        ],
                    
                                          spacing: {
                                            line:350
                                          },
                                        }),
            
                                      ]
                                    }),
                                    new TableCell({
                                      borders: {
                                        top: {color: '000000'},
                                        bottom: {color: '000000'},
                                        left: {color: 'ffffff'},
                                        right: {color: 'ffffff'},
                                      },
                                      margins: {
                                        right: convertInchesToTwip(0.1)
                                      },
                                      width:{
                                        size:50,
                                        type: WidthType.PERCENTAGE,
                                      },
                                      children: [
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'32', color:"#808080", text: "Receipt"})],
                                          alignment: AlignmentType.CENTER,
                                          
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'24', color:"#808080", text: "Original"})],
                                          alignment: AlignmentType.CENTER,
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'24', color:"#808080", text: "____________________________________"})],
                                          alignment: AlignmentType.CENTER,
                                        }),
                                        new Paragraph({
                                          text: '',
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'20', color:"#808080", text: "Document №"}),
                                            new TextRun({size:'20', text: `       ${numberReceipt}`}),
                                          ]
                                        }),

                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'20', color:"#808080", text: "Date"}),
                                            new TextRun({size:'20', text: `                     ${justStart}`}),
                                          ]
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'20', color:"#808080", text: "Seller"}),
                                            new TextRun({size:'20', text: `                   Mr. Phatsakon Kuadkaew`}),
                                          ]
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'24', color:"#808080", text: "____________________________________"})],
                                          alignment: AlignmentType.CENTER,
                                        }),
                                        new Paragraph({
                                          text: '',
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'20', color:"#808080", text: "Contact"}),
                                            new TextRun({size:'20', text: `                ${infoAgreem.addBooking.fio}`}),
                                          ]
                                        }),
                                        new Paragraph({
                                          children: [
                                            new TextRun({size:'20', color:"#808080", text: "Phone"}),
                                            new TextRun({size:'20', text: `                  ${infoAgreem.addBooking.phone}`}),
                                          ]
                                        }),
                                      ]
                                    })
                                  ]
                                })
                              ]
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "___________________________________________________________________________"})],
                              alignment: AlignmentType.CENTER,
                              spacing: {
                                line:250
                              },
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', text: "   #"}),
                                new TextRun({size:'20', text: "         Document №"}),
                                new TextRun({size:'20', text: `             Document Date`}),
                                new TextRun({size:'20', text: `             Due Date`}),
                                new TextRun({size:'20', text: `          Total Excluding VAT`}),
                                new TextRun({size:'20', text: `       Collected Amount`}),
                              ],
                              alignment: AlignmentType.JUSTIFIED,
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "   1"}),
                                new TextRun({size:'20', color:"#808080",text: `        ${numberReceipt}`}),
                                new TextRun({size:'20', color:"#808080",text: `             ${justStart}`}),
                                new TextRun({size:'20', color:"#808080",text: `                  ${justStart}`}),
                                new TextRun({size:'20', color:"#808080",text: `                     ${infoAgreem.addBooking.totalPrice}.00`}),
                                new TextRun({size:'20', color:"#808080",text: `                 ${infoAgreem.addBooking.totalPrice}.00`}),
                              ],
                              alignment: AlignmentType.JUSTIFIED,
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "___________________________________________________________________________"})],
                              alignment: AlignmentType.JUSTIFIED,
                              spacing: {
                                line:220
                              },
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Item(s) Selected"}),
                                new TextRun({size:'20', text: `       1 Transaction(s)`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Total"}),
                                new TextRun({size:'20', text: `             ${infoAgreem.addBooking.totalPrice}.00 THB`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'24', color:"#808080", text: "____________________________________"})],
                              alignment: AlignmentType.END,
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Non VAT/exempt amount"}),
                                new TextRun({size:'20', text: `             ${infoAgreem.addBooking.totalPrice}.00 THB`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Vatable amount"}),
                                new TextRun({size:'20', text: `                 0.00 THB`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Vat 0%"}),
                                new TextRun({size:'20', text: `                 0.00 THB`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({size:'20', color:"#808080", text: "Grand Total"}),
                                new TextRun({size:'20', text: `             ${infoAgreem.addBooking.totalPrice}.00 THB`}),
                              ],
                              alignment: AlignmentType.END,
                              spacing: {
                                line:400
                              },
                            }),

                                    //ДОБАВЛЯЕМ Подпись В РИСИП!!!!!!!
                                    !isKok && 
                                    new Paragraph({
                                      children:[
                                    new ImageRun({
                                      
                                      data: Uint8Array.from(atob(facs), c =>
                                        c.charCodeAt(0)
                                      ),
                                      transformation: {
                                        width: 100,
                                        height: 50
                                      },
                
                                      floating: {
                                        zIndex: 100,
                                        horizontalPosition: {
                                            offset: 4614400,
                                        },
                                        verticalPosition: {
                                            offset: 8884400,
                                        },
                                    },
                
                                    }),
                                  ],
                                }),
                                                                                                      //ДОБАВЛЯЕМ Штамп В инвойс!!!!!!!
                                                                                                      new Paragraph({
                                                                                                        children:[
                                                                                                      new ImageRun({
                                                                                                        
                                                                                                        data: Uint8Array.from(atob(stamp), c =>
                                                                                                          c.charCodeAt(0)
                                                                                                        ),
                                                                                                        transformation: {
                                                                                                          width: 235,
                                                                                                          height: 110
                                                                                                        },
                                                                                  
                                                                                                        floating: {
                                                                                                          zIndex: 10,
                                                                                                          horizontalPosition: {
                                                                                                              offset: 2454400,
                                                                                                          },
                                                                                                          verticalPosition: {
                                                                                                              offset: 8674400,
                                                                                                          },
                                                                                                      },
                                                                                  
                                                                                                      }),
                                                                                                    ],
                                                                                                  }),

                            // new Paragraph({
                            //   text: '',
                            // }),
                            // new Paragraph({
                            //   text: '',
                            // }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),

                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            // new Paragraph({
                            //   text: '',
                            // }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              text: '',
                            }),
                            new Paragraph({
                              children:[
                              new TextRun({size:'20', text: "Payment Received by: "}),
                              new TextRun({size:'20', italics:true, underline: {}, text: "Cash"}),

                            ],
                              spacing: {
                                line:350
                              },
                            }),
                            new Paragraph({
                              text: '',
                            }),
            
            
                            new Table({
                              width:{
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              indent: {
                                size: 1,
                                type: WidthType.DXA
                              },
                              rows: [
                              new TableRow({
                                children: [
            
                                new TableCell({
                                  borders: {
                                    top: {color: '000000'},
                                    bottom: {color: '000000'},
                                    left: {color: 'ffffff'},
                                    right: {color: 'ffffff'},
                                  },
                                  margins: {
                                    right: convertInchesToTwip(0.1)
                                  },
                                  width:{
                                    size: 48,
                                    type: WidthType.PERCENTAGE,
                                  },
                                  children: [
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', text: `${infoAgreem.addBooking.fio}`}),
                                    ],
                
                                      spacing: {
                                        line:350
                                      },
                                    }),
                                    new Paragraph({
                                      text: '',
                                    }),
                                    new Paragraph({
                                      text: '',
                                    }),
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', color:"#808080", text: `________________  _______________`}),
                                    ],
                
                                      spacing: {
                                        line:350
                                      },
                                    }),
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', text: `          Paid by                       Date`}),
                                    ],
                
                                      spacing: {
                                        line:200
                                      },
                                    }),
                                  ]
                                }),
                                
                                new TableCell({
                                  borders: {
                                    top: {color: '000000'},
                                    bottom: {color: '000000'},
                                    left: {color: 'ffffff'},
                                    right: {color: 'ffffff'},
                                  },
                                  margins: {
                                    right: convertInchesToTwip(0.1)
                                  },
                                  
                                  width:{
                                    size: 48,
                                    type: WidthType.PERCENTAGE,
                                  },
                                  children: [
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', text: "Rokki co., ltd"}),
                                    ],
                                      alignment: AlignmentType.END,
                                      spacing: {
                                        line:350
                                      },
                                    }),
                                    new Paragraph({
                                      text: '',
                                    }),
                                    new Paragraph({
                                      text: '',
                                    }),
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', color:"#808080", text: `________________  _______________`}),
                                    ],
                                    alignment: AlignmentType.END,
                                      spacing: {
                                        line:350
                                      },
                                    }),
                                    new Paragraph({
                                      children:[
                                      new TextRun({size:'20', text: `                              Collected by                  Date`}),
                                    ],
                                      spacing: {
                                        line:200
                                      },
                                    }),
                                  ]
                                })
                              ]
                            })
                          ]
                        })



          ]
        }]
      })
    console.log(props.isKok)
    const fileName = `statement${infoAgreem.dateAgreement}.docx`;
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, fileName);
    });
  };

  return (
    <div>
      <button className="redButton" onClick={()=> {handleSaveClick()}}>Print contract</button>
    </div>
  );
}

export default Agreement