
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {doc, updateDoc, getFirestore, deleteDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import '../css/AddBike.css'
import { useEffect, useState } from 'react'
import firebaseConfig from './firebaseConfig'

const RedPrice = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [redReservation, setRedReservation] = useState(null)

    useEffect(() => {
        if (props.reserv) { 
            setRedReservation(props.reserv)
        }
      }, [props]);

    //Функция изменения значения
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRedReservation((prevData) => ({
          ...prevData,
          addReservation: {
            ...prevData.addReservation,
            [name]: value
          }
        }));
      };

        
  const updateDocument = async () => {
    try {
      const documentRef = doc(db, props.user.bikesprice, props.reserv.id);
      await updateDoc(documentRef, redReservation);
      window.location.reload()
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}
const deleteDocument = async () => {
  try {
    const documentRef = doc(db, props.user.bikesprice, props.reserv.id); 
    await deleteDoc(documentRef);
    window.location.reload();

  } catch (error) {
    console.error("Ошибка при удалении документа из базы данных", error);
  }
};
    return (
        <div >
            {redReservation &&
            <div className="contRedAdd" style={{backgroundColor:"#d1a7b8", paddingTop:"4%", width:"87%", marginLeft:"7%", minWidth:"945px", margin:"auto", boxSizing:"border-box", marginTop:"40px"}}>
                <h3 style={{margin:"0", marginBottom:"10px"}}>Редактирование расценки</h3>
                <div className="contInput">
                     <p>Модель:</p>
                    <input 
                                  name="model"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.model}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                    <p>Номер:</p>
                    <input 
                                  name="number"
                                  type='number'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.number}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                     <p>Цена посуточно:</p>
                    <input 
                                  name="priceday"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.priceday}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Цена за неделю:</p>
                    <input 
                                  name="priceweek"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.priceweek}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Цена две недели:</p>
                    <input 
                                  name="pricetwoweek"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.pricetwoweek}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Цена помесячно:</p>
                    <input 
                                  name="pricemonth"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.pricemonth}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Депозит:</p>
                    <input 
                                  name="deposit"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.deposit}
                                  onChange={handleChange}
                        />
                    </div>
                    
                    <div>
                        <button className="redButton" onClick={updateDocument}>Сохранить</button>
                        <button className="redButton" style={{backgroundColor:"#f09e9e", cursor:"pointer"}} onClick={deleteDocument}>Удалить расценки</button>
                    </div>
                    
            </div>
}
        </div>
    )
}

export default RedPrice