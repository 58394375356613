
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {doc, updateDoc, getFirestore, deleteDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import '../css/AddBike.css'
import { useEffect, useState } from 'react'
import firebaseConfig from './firebaseConfig'

const RedWarehouse = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [redWarehouse, setRedWarehouse] = useState(null)

    useEffect(() => {
        setRedWarehouse(props.exp)
      }, [props]);

    //Функция изменения значения
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRedWarehouse((prevData) => ({
          ...prevData,
          addWar: {
            ...prevData.addWar,
            [name]: value
          }
        }));
      };

        
  const updateDocument = async () => {
    try {
      const documentRef = doc(db, props.user.warehouse, props.exp.id);
      await updateDoc(documentRef, redWarehouse);
      window.location.reload()
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}
const deleteDocument = async () => {
  try {
    const documentRef = doc(db, props.user.warehouse, props.exp.id); 
    await deleteDoc(documentRef);
    window.location.reload();

  } catch (error) {
    console.error("Ошибка при удалении документа из базы данных", error);
  }
};
    return (
        <div >
            {redWarehouse &&
            <div className="contRedAdd" style={{backgroundColor:"#d1a7b8", paddingTop:"4%"}}>
                <h3 style={{margin:"0", marginBottom:"10px"}}>Редактировать</h3>
                <div className="contInput">
                     <p>Название:</p>
                    <input 
                                  name="name"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redWarehouse.addWar.name}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Количество:</p>
                    <input 
                                  name="amount"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redWarehouse.addWar.amount}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                    <p>Дата расхода:</p>
                    <input 
                                  name="dateAdd"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={redWarehouse.addWar.date}
                                  onChange={handleChange}
                        />
                      </div>
                    <div>
                        <button className="redButton" onClick={updateDocument}>Сохранить</button>
                        <button className="redButton" style={{backgroundColor:"#f09e9e", cursor:"pointer"}} onClick={deleteDocument}>Удалить со склада</button>
                    </div>
                    
            </div>
}
        </div>
    )
}

export default RedWarehouse