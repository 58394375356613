import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {collection, getFirestore,getDocs, query,limit } from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';
import './App.css';
import { Header } from './js/Header';
// import { ShowBike } from './js/ShowBike'
import { RedAdd } from './js/RedAdd'
import { AddBike} from './js/AddBike'
import firebaseConfig from './js/firebaseConfig'
// import Block from './js/Block'
import { AllCount } from './js/AllCount';
import { EnterWindow } from './js/EnterWindow';
import { Reservations } from './js/Reservations';
import {ListBikes} from './js/ListBikes'
import { ListAllBooking } from './js/ListAllBooking';
// import { useNavigate } from 'react-router-dom';
import { Price } from './js/Price';
import { Warehouse} from './js/Warehouse'
import nameUser from './users.json'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  // const navigate = useNavigate()
  
  const [allData, setAllData] = useState(null)

  const [allBooking, setAllBooking] = useState(null)

  const [allExpenses, setAllExpenses] = useState(null)

  const [warehouse, setWarehouse] = useState(null)

  const [allReservations, setAllReservations] = useState(null)

  const [price, setPrice] = useState(null)

  const [par, setPar] = useState(true)

  const [user, setUser] = useState(null)

  const [headButton, setHeadButton] = useState(false)

  //Отдельный функционал для Кока
  const [isKok, setIsKok] = useState(false)
   //Кольцов?
   const [isKolcov, setIsKolcov] = useState(false)

  //Видимость меню
  const [visMenu, setVisMenu] = useState(false)


  useEffect(()=> { 
      // console.log("yr"+localStorage.getItem('par'))
      if (localStorage.getItem('uid1') === process.env.REACT_APP_UID_KEY1 || localStorage.getItem('uid1') === process.env.REACT_APP_UID_KEY2) {
          // console.log(localStorage.getItem('par'))
          setUser(nameUser.yellowBike)
          setPar(false)
          
      } else if (localStorage.getItem('uid1') === process.env.REACT_APP_UID_KEYVIL) {
          setUser(nameUser.kolcov)
          setPar(false)
          setIsKolcov(true)
      
      } else if (localStorage.getItem('uid1') === process.env.REACT_APP_UID_KEY3 ) {
        // console.log(localStorage.getItem('par'))
        setUser(nameUser.yellowBike)
        setPar(false)
        setIsKok(true)
        
    }
      
  },[])
  useEffect(()=> { 
    if (localStorage.getItem('uid') === process.env.REACT_APP_UID_KEY1 || localStorage.getItem('uid') === process.env.REACT_APP_UID_KEY2) {
      setHeadButton(true)
    }
  },[])

  const menu = () => {
    setVisMenu(!visMenu)
  }

  const getKolcov = () => {
    localStorage.setItem("uid1", process.env.REACT_APP_UID_KEYVIL);
    window.location.reload()
  }
  const getYellow = () => {
    localStorage.setItem("uid1", process.env.REACT_APP_UID_KEY1);
    window.location.reload()
  }

  useEffect(()=> { 
    //Извлекаем байки из базы данных
    if (user) {
    const fetchData = async () => {
      try {
        console.log("Извлекаем в APP из Bikes")
        const bikesCollection = collection(db, user.bikes);
        const bikesSnapshot = await getDocs(bikesCollection);
        const bikesData = bikesSnapshot.docs.map((doc) => {
          // Получаем ID документа
          const id = doc.id;
          // Получаем данные документа
          const data = doc.data();
          return { id, ...data };
        });
        bikesData.sort((a, b) => a.addBike.model.localeCompare(b.addBike.model));
        setAllData(bikesData);
        // console.log(localStorage.getItem('uid'))
      } catch (error) {
        console.error('Ошибка при извлечении данных:', error);
      }
    };

    fetchData();
  }
},[user])
  //Извлекаем аренды из базы данных
useEffect(()=> { 
  if (user) {
  const fetchData = async () => {
    try {
      console.log("Извлекаем в APP из Booking")
      const bookingCollection = collection(db, user.booking);
      const bookingSnapshot = await getDocs(bookingCollection);
      const bookingData = bookingSnapshot.docs.map((doc) => {
        const id = doc.id;
        const data = doc.data();
        return { id, ...data };
      });
      setAllBooking(bookingData);
    } catch (error) {
      console.error('Ошибка при извлечении данных:', error);
    }
  };

  fetchData();
}
},[user])
//Извлекаем только 20 аренд для экономии. Нужно реализовать около 200 с сортировкой по новым, и кнопку запросить все, чтобы искать или считать
// useEffect(() => {
//   if (user) {
//     const fetchData = async () => {
//       try {
//         console.log("Извлекаем в APP из Booking");

//         const bookingCollection = collection(db, user.booking);
//         const bookingQuery = query(bookingCollection, limit(20));

//         const bookingSnapshot = await getDocs(bookingQuery);
//         const bookingData = bookingSnapshot.docs.map((doc) => {
//           const id = doc.id;
//           const data = doc.data();
//           return { id, ...data };
//         });

//         setAllBooking(bookingData);
//       } catch (error) {
//         console.error('Ошибка при извлечении данных:', error);
//       }
//     };

//     fetchData();
//   }
// }, [user]);

useEffect(()=> {
  if (user) { 
  //Извлекаем расходы из базы данных
  const fetchData = async () => {
    try {
      console.log("Извлекаем в APP из Booking")
      const bookingCollection = collection(db, user.expenses);
      const bookingSnapshot = await getDocs(bookingCollection);
      const bookingData = bookingSnapshot.docs.map((doc) => {
        // Получаем ID документа
        const id = doc.id;
        // Получаем данные документа
        const data = doc.data();
        return { id, ...data };
      });
      setAllExpenses(bookingData);
      // console.log(bookingData)
    } catch (error) {
      console.error('Ошибка при извлечении данных:', error);
    }
  };

  fetchData();
}
},[user])
useEffect(()=> {
  if (user) { 
  //Извлекаем Склад из базы данных
  const fetchData = async () => {
    try {
      console.log("Извлекаем в APP из Warehouse")
      const bookingCollection = collection(db, user.warehouse);
      const bookingSnapshot = await getDocs(bookingCollection);
      const bookingData = bookingSnapshot.docs.map((doc) => {
        // Получаем ID документа
        const id = doc.id;
        // Получаем данные документа
        const data = doc.data();
        return { id, ...data };
      });
      setWarehouse(bookingData);
      console.log(bookingData)
    } catch (error) {
      console.error('Ошибка при извлечении данных:', error);
    }
  };

  fetchData();
}
},[user])

useEffect(()=> { 
  if (user) {
  //Извлекаем бронирования из базы данных
  const fetchData = async () => {
    try {
      console.log("Извлекаем в APP из reservations")
      const bookingCollection = collection(db, user.reservations);
      const bookingSnapshot = await getDocs(bookingCollection);
      const bookingData = bookingSnapshot.docs.map((doc) => {
        // Получаем ID документа
        const id = doc.id;
        // Получаем данные документа
        const data = doc.data();
        return { id, ...data };
      });
      setAllReservations(bookingData);
      // console.log(bookingData)
    } catch (error) {
      console.error('Ошибка при извлечении данных:', error);
    }
  };

  fetchData();
}
},[user])

useEffect(()=> { 
  if (user) {
  //Извлекаем Цены на байки из базы данных
  const fetchData = async () => {
    try {
      console.log("Извлекаем в APP из Bikesprice")
      const bikesCollection = collection(db, user.bikesprice);
      const bikesSnapshot = await getDocs(bikesCollection);
      const bikesData = bikesSnapshot.docs.map((doc) => {
        // Получаем ID документа
        const id = doc.id;
        // Получаем данные документа
        const data = doc.data();
        return { id, ...data };
      });
      setPrice(bikesData);
      // console.log(localStorage.getItem('uid'))
    } catch (error) {
      console.error('Ошибка при извлечении данных:', error);
    }
  };
  fetchData();
}
},[user])

  const exit = () => {
    localStorage.removeItem('uid')
    localStorage.removeItem('uid1')
    window.location.reload()
  }
  
  return (
    <>
    {visMenu && 
    <div className='headBlock'>
    <a href='/'><div className="btnHome">Home</div></a>
    <div className="btnExit" onClick={exit}>Exit</div>
    {headButton && !isKok && <div className="btnExit" style={{backgroundColor:"#90db8c"}} onClick={getKolcov}>VIL</div>}
    {headButton && !isKok && <div className="btnExit" style={{backgroundColor:"#90db8c", marginBottom:"0px"}} onClick={getYellow}>YB</div>}
    </div>
}
    {par ? <EnterWindow/>:
    <BrowserRouter>
    <Header menu={menu} isKok={isKok}/>
      <Routes>
        <Route path='/' element={<ListBikes isKok={isKok} allData={allData} allBooking={allBooking} allExpenses={allExpenses}/>} />
        {/* {allData && allData.map((item, ind) => <Route key={ind} path={`/showbike${item.addBike.id}`} element={<ShowBike allData={item}/>} />) } */}
        {!isKok && <Route path='/addbike' element={<AddBike user={user}/>} />}
        {!isKok && <Route path='/warehouse' element={<Warehouse warehouse={warehouse} user={user} />} />}
        {!isKok && <Route path='/allcount' element={<AllCount allBike={allData} allBooking={allBooking} allExpenses={allExpenses} user={user}/>} />}
        {!isKok && <Route path='/allbooking' element={<ListAllBooking allBike={allData} allBooking={allBooking} allExpenses={allExpenses}/>} />}
        {allData && allData.map((item, ind) => <Route key={ind} path={`/redadd${item.addBike.id}`} element={<RedAdd isKok={isKok} isKolcov={isKolcov} allData={item} allBooking={allBooking} allExpenses={allExpenses} user={user}/>} />) }
        {!isKok && <Route path='/reservations' element={<Reservations allReservations={allReservations} user={user}/>} />}
        <Route path='/price' element={<Price isKok={isKok} price={price} user={user} isKolcov={isKolcov}/>} />
      </Routes>
    </BrowserRouter>
}
</>
  );
}

export default App;
