import { useEffect, useState } from 'react';
import '../css/ListExpenses.css'
import AddWarehouse from './AddWarehouse';
import RedWarehouse from './RedWarehouse';

const Warehouse = props => {
 
    const [allData, setAllData] = useState(null)
    const [exp, setExp] = useState(null)

    useEffect(()=> { 
      if (props.warehouse) {
        const bikesData = props.warehouse
        console.log(props.warehouse)
        setAllData(bikesData);
      }
      // eslint-disable-next-line
  },[props])
  
    const showExp = (item) => {
      setExp(item)
    }

    return (
      <div style={{paddingLeft:"5%", paddingRight:"5%"}}>
    <h2 style={{margin:"0", marginBottom:"20px"}}>Склад</h2>
      <table className='tableExp'>
        <thead >
          <tr >
              <th>Название</th> 
              <th>Количество</th> 
              <th>Дата ред.</th> 
          </tr>
        </thead>
        <tbody>
          {allData && allData.map((item, ind)=> 
            <tr onClick={()=> {showExp(item); console.log(props.user)}} key={ind} style={{cursor:"pointer"}}>
                <td>{item.addWar.name}</td>
                <td>{item.addWar.amount}</td>
                <td>{item.addWar.dateAdd}</td>
            </tr>
          )}
        </tbody>
      </table>
      {exp && 
      <RedWarehouse exp={exp} item={props.item} user={props.user}/>
      }
      {allData && <AddWarehouse id={props.id} user={props.user}/>
      }

      </div>
    );
  }

export {Warehouse}