import '../css/RedAdd.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { initializeApp } from 'firebase/app';
import {doc, getFirestore, deleteDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import firebaseConfig from './firebaseConfig';
import AddBooking  from './AddBooking';
import ListBooking from './ListBooking';
// import ModalSuccess from './ModalSuccess';
import ListExpenses from './ListExpenses';
import AddPictures from './AddPictures';
import ModalDel from './ModalDel';

//Подключаемся к FireBase
const app = initializeApp(firebaseConfig);
// const storage = getStorage(app);
const db = getFirestore(app);

const moment = require('moment');

const RedAdd = item => {
    const navigate = useNavigate()
    const [newData, setNewData] = useState(item.allData)

    const [isKok, setIsKok] = useState(item.isKok)

    // const [visModal, setVisModal] = useState(false)

    const [visAddRent, setVisAddRent] = useState(false)

    //Видимость модального окна удаления
    const [visModalDel, setVisModalDel] = useState(false)

    const visDel = () => {
        setVisModalDel(false)
    }

    //Редактирование объекта объявления
    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setNewData((prevState) => ({
    //       ...prevState,
    //       [name]: value
    //     }));
    //     }
        const handleChange = (event) => {
            const { name, value } = event.target;

            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  [name]: value,
                  dateMileage: name === "mileageNow" ? String(Date.now()) : prevData.addBike.dateMileage
                }
              }));
          };
        const handleCheckboxChange = () => {
            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  available: !prevData.addBike.available // Обновляем available внутри addBike
                }
              }));
        }
        const handleCheckboxCantRent = () => {
            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  cantRent: !prevData.addBike.cantRent // Обновляем available внутри addBike
                }
              }));
        }
        const handleCheckboxCompany = () => {
            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  company: !prevData.addBike.company // Обновляем available внутри addBike
                }
              }));
        }
        const handleCheckboxIsBooking = ()=> {
            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  isBooking: !prevData.addBike.isBooking // Обновляем available внутри addBike
                }
              }));
        }
        const handleCheckboxSell = () => {
            setNewData((prevData) => ({
                ...prevData,
                addBike: {
                  ...prevData.addBike,
                  sell: !prevData.addBike.sell // Обновляем available внутри addBike
                }
              }));
        }

    //Запись обновленного объявления в базу данных. Переносим в компонент сохранения изображений
    // const updateDocument = async () => {
    //     try {
    //       const documentRef = doc(db, 'bikes', item.allData.id);
    //       await updateDoc(documentRef, newData);
    //       setVisModal(true)
    //       setTimeout(()=>{setVisModal(false)}, 2000)

    //     } catch (error) {
    //       console.error('Ошибка при обновлении документа:', error);
    //       return false;
    //     }
    // }
    const deleteDocument = async () => {
        try {
          const documentRef = doc(db, item.user.bikes, item.allData.id); 
          await deleteDoc(documentRef);
          navigate('/')
        } catch (error) {
          console.error("Ошибка при удалении документа из базы данных", error);
        }
      };
    
    
    return (
        <div >
            {/* {visModal && <ModalSuccess/>} */}
            {visModalDel && <ModalDel deleteDocument={deleteDocument} visDel={visDel}/>}
            <div style={{position:"sticky", top:"0px", backgroundColor:"white", width:"100%", minWidth:"1010px"}}>
                <h2 style={{marginLeft:"30px"}}>{item.allData.addBike.brand} {item.allData.addBike.model}  / {item.allData.addBike.color}  / {item.allData.addBike.year} year / ({item.allData.addBike.regNumber}) / {item.allData.addBike.WIN} </h2>
            </div>
            <div className='contRed'>
                <div className='informShow' style={newData.addBike.sell ? {backgroundColor:"#ebebeb"}: newData.addBike.cantRent ? {backgroundColor: "#e8e08b"}: newData.addBike.available ? newData.addBike.isBooking ?{backgroundColor:"#6ec1eb"}: {backgroundColor:"#b2edb8"}:{backgroundColor:"#e8c4b7"}}>
                <h2 style={{margin:"0", marginBottom:"10px"}}>{newData.addBike.sell ? "Sold": newData.addBike.available ? newData.addBike.cantRent ? "Available, need repairs": "Available" : "Not Available"}</h2>
                {newData.addBike.imageUrl && newData.addBike.imageUrl[1] ? <img src={newData.addBike.imageUrl[1]} style={{width:"100%", height:"auto", borderRadius:"5px", marginBottom:"20px"}} alt="X"/>:<div></div>}
                <div className="contInput">
                <p>Brand:</p>
                    <input 
                                  name="brand"
                                  type='text'
                                  osnWheel={(e) => e.target.blur()}
                                  value={newData.addBike.brand}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Model:</p>
                    <input 
                                  name="model"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.model}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                    <div className="contInput">
                    <p>Year:</p>
                    <input 
                                  name="year"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.year}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                    <div className="contInput">
                    <p>Color: </p>
                        <input 
                                  name="color"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.color}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                   {!isKok && <div className="contInput">
                    <p>Buy price: </p>
                        <input 
                                  name="buyPrice"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.buyPrice}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>}
                    <div className="contInput">
                    <p>Buy date:</p>
                    <input 
                                  name="dateBuy"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.dateBuy}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Number:</p>
                    <input 
                                  name="regNumber"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.regNumber}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>WIN:</p>
                    <input 
                                  name="WIN"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.WIN}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput" style={{backgroundColor:"#abbcff"}}>
                    <p>Mileage:</p>
                    <input 
                                  name="mileageNow"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.mileageNow}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    {newData.addBike.dateMileage &&
                    <div className="contInput">
                        <p>Date mileage: </p>
                        <p>{moment(parseInt(newData.addBike.dateMileage)).format("DD-MM-YYYY")}</p>
                    </div>
                    }
                    <div className="contInput">
                    <p>Last change oil mileage:</p>
                    <input 
                                  name="mileageLastTo"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.mileageLastTo}
                                  onChange={handleChange}

                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Last change oil date:</p>
                    <input 
                                  name="lastToDate"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.lastToDate}
                                  onChange={handleChange}
                   
                        />
                    
                    </div>
                    <div className="contInput" style={{backgroundColor:"#a2e084"}}>
                    <p>Left km for change oil:</p>
                    <input 
                                  name="lastToDate"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={parseInt(newData.addBike.tokm)+parseInt(newData.addBike.mileageLastTo-newData.addBike.mileageNow)}
                                  onChange={handleChange}
                                  readOnly={true}
                        />
                    
                    </div>
                    <div className="contInput" >
                    <p>Mileage for change oil:</p>
                    <input 
                                  name="tokm"
                                  placeholder='Сколько км нужно проехать для ТО'
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.tokm}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
            </div>
                    <div className="contInput">
                    <p>Insurance end:</p>
                    <input 
                                  name="insurance"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.insurance}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Comment:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.comment}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>№ box:</p>
                    <input 
                                  name="bikeNumber"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.bikeNumber}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
            </div>
                  <div className="contInputCheck">
                    <p>Available:</p>
                    <input 

                                  name="available"
                                  type='checkbox'
                                  checked={newData.addBike.available}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={handleCheckboxChange}
                          
                        />
                    
                    </div>
                    {!isKok && <div className="contInputCheck" style={{borderTop:"1px solid grey", borderBottom:"1px solid grey", paddingTop:"5px", paddingBottom:"5px"}}>
                    <p style={{margin:"0px"}}>Забронирован:</p>
                    <input 

                                  name="isBooking"
                                  type='checkbox'
                                  checked={newData.addBike.isBooking}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={handleCheckboxIsBooking}
                          
                        />
                    
                    </div>}
                    {newData.addBike.isBooking && <div className="contInput" style={{marginTop:"10px"}}>
                    <p>Дата брони:</p>
                    <input 
                                  name="dateBooking"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.dateBooking}
                                  onChange={handleChange}
                        />
                    
                    </div>}
                    {!isKok && <div className="contInputCheck" style={{borderTop:"1px solid grey", borderBottom:"1px solid grey", paddingTop:"5px", paddingBottom:"5px"}}>
                    <p style={{margin:"0px"}}>Не на ходу:</p>
                    <input 

                                  name="cantRent"
                                  type='checkbox'
                                  checked={newData.addBike.cantRent}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={handleCheckboxCantRent}
                          
                        />
                    
                    </div>}
                    {!isKok && <div className="contInputCheck" style={{borderTop:"1px solid grey", borderBottom:"1px solid grey", paddingTop:"5px", paddingBottom:"5px"}}>
                    <p style={{margin:"0px"}}>Рег. на компанию:</p>
                    <input 

                                  name="company"
                                  type='checkbox'
                                  checked={newData.addBike.company}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={handleCheckboxCompany}
                          
                        />
                    
                    </div>}
                    {!isKok && <div className="contInputCheck" style={{borderTop:"1px solid grey", borderBottom:"1px solid grey", paddingTop:"5px", paddingBottom:"5px"}}>
                    <p style={{margin:"0px"}}>Продан:</p>
                    <input 

                                  name="sell"
                                  type='checkbox'
                                  checked={newData.addBike.sell}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={handleCheckboxSell}
                        
                        />
                    
                    </div>}
                    {newData.addBike.sell && <div className="contInput" style={{marginTop:"10px"}}>
                    <p>Дата продажи:</p>
                    <input 
                                  name="dateSell"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.dateSell}
                                  onChange={handleChange}
                        />
                    
                    </div>}
                    {newData.addBike.sell && 
                    <div className="contInput">
                         <p>Цена продажи: </p>
                        <input 
                                  name="sellPrice"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={newData.addBike.sellPrice}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div> }
                        
                         {/* <button className='redAddButton' onClick={updateDocument} >Сохранить изменения</button> */}
                <AddPictures isKok={isKok} data={newData} user={item.user}/>
                        {!isKok && <button className='redAddButton' style={{backgroundColor:"#f09e9e"}} onClick={()=>setVisModalDel(true)} >Удалить Байк</button>}
                </div>
                    <div >
                    <div className="contRent" style={{marginBottom:"20px"}}>
                         <ListBooking isKok={isKok} isKolcov={item.isKolcov} id={item.allData.id} item={newData.addBike} allBooking={item.allBooking} allExpenses={item.allExpenses} user={item.user}/>
                    {!visAddRent&&
                        <button className='redAddButton' onClick={()=>setVisAddRent(true)}>Add rent</button>
                    }
                        {visAddRent&&
                         <AddBooking isKok={isKok} id={item.allData.id} user={item.user}/>
                    }
                    </div>
                    {!isKok && <div className="contRent">
                        <ListExpenses allExpenses={item.allExpenses} id={item.allData.id} user={item.user}/>
                    </div>}
                    </div>
            </div>
        </div>
    )
}

export { RedAdd }