
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddExpenses.css'
import data from '../fields.json'
import { useState } from 'react'
import firebaseConfig from './firebaseConfig';

const AddWarehouse = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addWar, setAddWar] = useState(data.warehouse)

    //Функция изменения значения
    const handleChange = (event) => {
        // setAddWar((prevState) => ({
        //     ...prevState,
        //     id: props.id
        //   }));
        const { name, value } = event.target;
        setAddWar((prevState) => ({
          ...prevState,
          [name]: value
        }));
        }

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addWar).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddWar(clearedUser);
  };
        
    const handleSave = async () => {
        try {
            // Создаем новый документ в коллекции "users" с данными пользователя
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.warehouse), {
                addWar
            });
            // Очищаем поля ввода после сохранения данных
            handleClear()
            console.log(addWar)
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
    }
    return (
        <div>
            <div className="contAddExp">
                <h3 style={{margin:"0"}}>Добавить на склад</h3>

                <div className="contInputExp">
                    <p>Название:</p>
                    <input 
                                  name="name"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addWar.name}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInputExp">
                    <p>Количество:</p>
                    <input 
                                  name="amount"
                                  type='number'
                                  onWheel={(e) => e.target.blur()}
                                  value={addWar.amount}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInputExp">
                    <p>Дата расхода:</p>
                    <input 
                                  name="dateAdd"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addWar.date}
                                  onChange={handleChange}
                        />
                      </div>
            
                    <button className="redButtonExp" onClick={handleSave}>Сохранить</button>
            </div>
        </div>
    )
}

export default AddWarehouse