import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ListBikes.css'
import Search  from './Search';

const moment = require('moment');

const ListBikes = props => {
 
    const navigate = useNavigate()

    const redAdd = (id) => {
        navigate(`/redAdd${id}`)
        // console.log(props)
    }
//Пробуем чтобы сортировать по дате
const [sortData, setSortData] = useState(null);
const [upSort, setUpSort] = useState(null)

//Отдельный функционал для кока
const [isKok, setIsKok] = useState(false)
//Сортируем только проданные
const [sell, setSell] = useState (false)

//Показываем консоли
const [showConsole, setShowConsole] = useState(false)

const onlySell = () => {
  setSell(!sell)
}
const console = () => {
  setShowConsole(!showConsole)
}

// Создайте функцию для сравнения объектов по ключу addBike.dateEnd
const handleSortClick = () => {
  const sortedUpSort = [...upSort].sort((a, b) => {
    const dateA = !a.addBike.dateEnd ? new Date('9999-12-31') : new Date(a.addBike.dateEnd);
    const dateB = !b.addBike.dateEnd  ? new Date('9999-12-31') : new Date(b.addBike.dateEnd);
    return dateA - dateB;
  });
  
  setUpSort(sortedUpSort);
  }
  //Сортировка по дате страховки
  const handleSortClickIn = () => {
    const sortedUpSort = [...upSort].sort((a, b) => {
      const dateA = !a.addBike.insurance ? new Date('9999-12-31') : new Date(a.addBike.insurance);
      const dateB = !b.addBike.insurance  ? new Date('9999-12-31') : new Date(b.addBike.insurance);
      return dateA - dateB;
    });
    
    setUpSort(sortedUpSort);
    }

  //Сортировка по дате пробега
  const handleSortClickDate = () => {
    const sortedUpSort = [...upSort].sort((a, b) => {
      const dateA = !a.addBike.dateMileage ? new Date('9999-12-31') : new Date(parseInt(a.addBike.dateMileage));
      const dateB = !b.addBike.dateMileage  ? new Date('9999-12-31') : new Date(parseInt(b.addBike.dateMileage));
      return dateA - dateB;
    });
    setUpSort(sortedUpSort);
    }

  //Сортировка по модели
  const handleNameClick = () => {

    const sortedUpSort1 = [...upSort]
    sortedUpSort1.sort((a, b) => 
      a.addBike.model.localeCompare(b.addBike.model));
    setUpSort(sortedUpSort1);
    }

    //Сортировка по порядковому номеру
    const handleNumberBike = () => {

      const sortedUpSort = [...upSort].sort((a, b) => {
        const dateA = parseInt(a.addBike.bikeNumber);
        const dateB = parseInt(b.addBike.bikeNumber);
        return dateA - dateB;
      });
      setUpSort(sortedUpSort);
      }

      //Сортировка по проценту
    const handlePercent = () => {

      const sortedUpSort = [...upSort].sort((a, b) => {
        const dateA = parseInt(a.addBike.percent);
        const dateB = parseInt(b.addBike.percent);
        return dateB - dateA;
      });
      setUpSort(sortedUpSort);
      }


useEffect(() => {
  if (props.allData) {
    // Копируем данные из props.allData в sortData
    setSortData([...props.allData]);
  }
  if(props) {
    setIsKok(props.isKok)
}
}, [props.allData, props.isKok]);

useEffect(() => {
  if (sortData && props.allBooking ) {
    // Создаем новый массив sortData с обновленными значениями addBike.dateEnd
    const updatedSortData = sortData.map((item) => {

      if (!item.addBike.available) {
        //Выбираем нужные бронирования
        const bookings = props.allBooking.filter((it) => it.addBooking.id === item.id);
        //Суммируем бронирования
        const totalSum = bookings.reduce((sum, total) => sum + parseInt(total.addBooking.totalPrice), 0);

        //Выбриаем нужные расходы
        let totalExp = 0
        if (props.allExpenses) {
        const expenses = props.allExpenses.filter((it) => it.addExp.id === item.id);
        //Суммируем расходы
        totalExp = expenses.reduce((sum, total) => sum + parseInt(total.addExp.amount), 0);
        }

        //Расчет процентов
        const percent = totalSum/((totalExp+parseInt(item.addBike.buyPrice))/100)

        if (bookings.length > 0) {
          // Находим максимальное значение dateEnd из найденных бронирований
          // const maxDateEnd = bookings.reduce((max, booking) =>
          //   booking.addBooking.dateEnd > max ? booking.addBooking.dateEnd : max, bookings[0].addBooking.dateEnd);

            const maxDateEnd = bookings.reduce((max, booking) =>
            booking.addBooking.dateEnd > max.dateEnd ? { dateEnd: booking.addBooking.dateEnd, fio: booking.addBooking.fio, phone: booking.addBooking.phone, comRent: booking.addBooking.comRent } : max,
            { dateEnd: bookings[0].addBooking.dateEnd, fio: bookings[0].addBooking.fio, phone: bookings[0].addBooking.phone, comRent: bookings[0].addBooking.comRent}
          );

          return {
            ...item,
            addBike: {
              ...item.addBike,
              dateEnd: maxDateEnd.dateEnd,
              fio: maxDateEnd.fio,
              phone: maxDateEnd.phone,
              comRent: maxDateEnd.comRent,
              totalSum: totalSum,
              totalExpenses: totalExp,
              percent: percent
            },
            
          };
        }
      }
            //Сколько процентов принес
            const bookings = props.allBooking.filter((it) => it.addBooking.id === item.id);
            if (bookings.length > 0) {
              const totalSum = bookings.reduce((sum, total) => sum + parseInt(total.addBooking.totalPrice), 0);
                //Выбриаем нужные расходы
                let totalExp = 0
                if (props.allExpenses) {
                const expenses = props.allExpenses.filter((it) => it.addExp.id === item.id);
                totalExp = expenses.reduce((sum, total) => sum + parseInt(total.addExp.amount), 0);
                }
            //Расчет процентов
              const percent = totalSum/((totalExp+parseInt(item.addBike.buyPrice))/100)

              return {
                ...item,
                addBike: {
                  ...item.addBike,
                  totalSum: totalSum,
                  totalExpenses: totalExp,
                  percent: percent
                },
                
              };

             
              // Находим максимальное значение dateEnd из найденных бронирований
              // const maxDateEnd = bookings.reduce((max, booking) =>
              //   booking.addBooking.dateEnd > max ? booking.addBooking.dateEnd : max, bookings[0].addBooking.dateEnd);
    

              //   const maxDateEnd = bookings.reduce((max, booking) =>
              //   booking.addBooking.dateEnd > max.dateEnd ? { dateEnd: booking.addBooking.dateEnd, fio: booking.addBooking.fio, phone: booking.addBooking.phone, comRent: booking.addBooking.comRent } : max,
              //   { dateEnd: bookings[0].addBooking.dateEnd, fio: bookings[0].addBooking.fio, phone: bookings[0].addBooking.phone, comRent: bookings[0].addBooking.comRent}
              // );
    
              // return {
              //   ...item,
              //   addBike: {
              //     ...item.addBike,
              //     dateEnd: maxDateEnd.dateEnd,
              //     fio: maxDateEnd.fio,
              //     phone: maxDateEnd.phone,
              //     comRent: maxDateEnd.comRent
              //   },
                
              // };
            }
            //До сюда

      return item;
    }); 
    
    // Обновляем состояние sortData с новыми данными
    const sortedUpSort = [...updatedSortData].sort((a, b) => {
      const dateA = !a.addBike.dateEnd ? new Date('9999-12-31') : new Date(a.addBike.dateEnd);
      const dateB = !b.addBike.dateEnd  ? new Date('9999-12-31') : new Date(b.addBike.dateEnd);
      return dateA - dateB;
    });
    //отфильтровываем проданные или не проданные и консоли не консоли
    if (sell){
    const filteredArray = sortedUpSort.filter(item => item.addBike.sell === true);
    setUpSort(filteredArray);
    } else {
      if (showConsole) {
        const filteredArray = sortedUpSort.filter(item => item.addBike.brand === "Playstation");
        setUpSort(filteredArray);
      } else {
      const filteredArray = sortedUpSort.filter(item => !item.addBike.sell && item.addBike.brand !== "Playstation");
      setUpSort(filteredArray);
      }
    }
  }
}, [sortData, props, sell, showConsole]);



    return (
      <div className="contListBikes">
        {upSort&& <Search isKok={isKok} upSort={upSort} sell={sell} onlySell={onlySell} console={console} showConsole={showConsole}/>}
        {upSort ?
        showConsole ?
      <table className='table' style={{}}>
        <thead >
          <tr >
              <th>#</th>
              <th style={{cursor:"pointer"}} onClick={handleNameClick}>Название Консоли</th> 
              <th style={{cursor:"pointer"}} onClick={handlePercent}>%</th>
              <th>Порядковый номер</th> 
              <th onClick={handleSortClick} style={{cursor:"pointer"}}>Дата/время окончания аренды</th>
              <th>Арендатор</th>
              <th >Комментарий</th> 
              <th onClick={handleNumberBike} style={{cursor:"pointer"}} >№</th> 
              {/* <th>Статус</th> */}
              
          </tr>
        </thead>
        <tbody>
          {props.allData && upSort.map((item, ind)=> 
            <tr key={ind} style={item.addBike.cantRent ? {backgroundColor: "#c9c9c9"}: item.addBike.available ? item.addBike.isBooking? {backgroundColor:"#6ec1eb", cursor:"pointer"} : {backgroundColor:"#b2edb8", cursor:"pointer"}:{backgroundColor:"#edd3b2", cursor:"pointer"}} onClick={()=> redAdd(item.addBike.id)}>
                <td>{ind+1}</td>
                <td style={{color:item.addBike.color, display:"flex"}}><div style={{backgroundColor:item.addBike.color, marginRight:"5px", borderRadius:"2px", width:"20px", height:"20px"}}></div>{item.addBike.brand} {item.addBike.model} {item.addBike.color}</td>
                <td >{parseInt(item.addBike.percent)}%</td>
                <td >{item.addBike.regNumber}</td>
                <td >{item.addBike.available ? "-":moment(item.addBike.dateEnd).format("DD-MM-YYYY HH:mm")} <span style={{color:"green", fontSize:"15px"}}>{item.addBike.comRent}</span></td>
                <td>{item.addBike.available ? "-":item.addBike.fio}</td>
                <td style={item.addBike.comment ? {backgroundColor:"#ed6f6f", fontSize:"12px"}:{}}>{item.addBike.comment ? item.addBike.comment: '-'}</td>
                <td >{item.addBike.bikeNumber}</td>
                {/* <td>{ item.addBike.available ? "-": moment((props.allBooking.find(it => it.addBooking.id === item.id)|| {}).addBooking.dateEnd).format("DD-MM-YYYY HH:mm") 

                }
                </td> */}
                {/* <td>{item.addBike.available ? "Свободен":"Занят"}</td> */}
            </tr>
          )}
        </tbody>
      </table>:
       <table className='table'>
       <thead >
         <tr >
             <th>#</th>
             <th style={{cursor:"pointer"}} onClick={handleNameClick}>Bike name</th> 
             {!isKok && <th style={{cursor:"pointer"}} onClick={handlePercent}>%</th>}
             <th>Number</th> 
             {/* <th>WIN</th>  */}
             {/* <th>Текущий пробег</th>  */}
             {/* <th>Пробег пос. ТО</th>  */}
             {/* <th onClick={handleSortClickTo} style={{cursor:"pointer"}}>Дата пос. ТО</th>  */}
             <th onClick={handleSortClickDate} style={{cursor:"pointer"}}>Date Maleage</th>
             <th>Km left change oil</th>
             <th onClick={handleSortClickIn} style={{cursor:"pointer"}}>Insurance end</th> 
             <th onClick={handleSortClick} style={{cursor:"pointer"}}>Date/time finish rent</th>
             <th>Name customer</th>
             <th >Comment</th> 
             <th onClick={handleNumberBike} style={{cursor:"pointer"}} >№ box</th> 
             {/* <th>Статус</th> */}
             
         </tr>
       </thead>
       <tbody>
         {props.allData && upSort.map((item, ind)=> 
         <>

           <tr key={ind} style={item.addBike.cantRent ? {backgroundColor: "#c9c9c9"}: item.addBike.available ? item.addBike.isBooking? {backgroundColor:"#6ec1eb", cursor:"pointer"} :{backgroundColor:"#b2edb8", cursor:"pointer"}:{backgroundColor:"#edd3b2", cursor:"pointer"}} onClick={()=> redAdd(item.addBike.id)}>
               <td style={{backgroundColor:item.addBike.company ? "#e3a3f0":"" }}>{ind+1}</td>
               <td style={{color:item.addBike.color, display:"flex"}}><div style={{backgroundColor:item.addBike.color, marginRight:"5px", borderRadius:"2px", width:"20px", height:"20px"}}></div>{item.addBike.brand} {item.addBike.model} {item.addBike.color}</td>
               {!isKok && <td >{parseInt(item.addBike.percent)}%</td>}
               <td >{item.addBike.regNumber}</td>
               {/* <td >{item.addBike.WIN}</td> */}
               {/* <td>{item.addBike.mileageNow}</td> */}
               {/* <td>{item.addBike.mileageLastTo}</td> */}
               {/* <td>{item.addBike.lastToDate}</td> */}
               <td>{item.addBike.dateMileage? moment(parseInt(item.addBike.dateMileage)).format("DD-MM-YYYY"):"-" }</td>
               <td
               style={(parseInt(item.addBike.mileageLastTo)+parseInt(item.addBike.tokm)-parseInt(item.addBike.mileageNow))<500 ? {backgroundColor:"#d1a7b8"}:{}}
               >{parseInt(item.addBike.mileageLastTo)+parseInt(item.addBike.tokm)-parseInt(item.addBike.mileageNow)}</td>
               <td>{item.addBike.insurance}</td>
               <td style={{backgroundColor:item.addBike.isBooking ? "#6ec1eb":""}}>
                 {item.addBike.available? `-`:moment(item.addBike.dateEnd).format("DD-MM-YYYY HH:mm")} <br/>
                 {item.addBike.isBooking && 
                 <span style={{color:"red", fontSize:"15px"}}>booking: {item.addBike.dateBooking}</span>
                 }
                 <span style={{color:"green", fontSize:"15px"}}>{item.addBike.comRent}</span>
                </td>
               {/* <td style={{color:item.addBike.isBooking? "red":""}}>{item.addBike.available? item.addBike.isBooking? `boking: ${item.addBike.dateBooking}`: `-`: item.addBike.isBooking? `boking: ${item.addBike.dateBooking}`+ moment(item.addBike.dateEnd).format("DD-MM-YYYY HH:mm"):moment(item.addBike.dateEnd).format("DD-MM-YYYY HH:mm")} <span style={{color:"green", fontSize:"15px"}}>{item.addBike.comRent}</span></td> */}
               <td>{item.addBike.available ? "-":item.addBike.fio}</td>
               <td style={item.addBike.comment ? {backgroundColor:"#ed6f6f", fontSize:"12px"}:{}}>{item.addBike.comment ? item.addBike.comment: '-'}</td>
               <td >{item.addBike.bikeNumber}</td>
               {/* <td>{ item.addBike.available ? "-": moment((props.allBooking.find(it => it.addBooking.id === item.id)|| {}).addBooking.dateEnd).format("DD-MM-YYYY HH:mm") 

               }
               </td> */}
               {/* <td>{item.addBike.available ? "Свободен":"Занят"}</td> */}
           </tr>
           </>
         )}
       </tbody>
     </table>:
      <div>Loading...</div>
}
      </div>
    
    );
  }

export {ListBikes}